import moment from 'moment';
import { setLoadingChats, setUnreadChats } from '../redux/slices/ChatSlice';
import { setSelectedCategory, setSelectedFile, setSelectedPrompt } from '../redux/slices/MasterSlice';

const errorMessageHandler = (responseData) => {
  const errorArray = [];
  if (responseData?.errors?.length) {
    responseData.errors.map((e) => errorArray.push(e?.message ?? e));
  } else if (responseData?.message) {
    errorArray.push(responseData.message);
  } else {
    errorArray.push('Something went wrong!');
  }
  return errorArray;
};

const formatUserData = (responseData) => ({
  id: responseData.id,
  firstName: responseData.first_name,
  lastName: responseData.last_name,
  jobTitle: responseData.user_profile?.job_title,
  language: responseData.user_profile?.language,
  role: responseData.user_profile?.role,
});

const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
};

const compareStartEndTime = (start, end) => {
  const startTime = moment(start);
  const endTime = moment(end);
  // return true if end date should be greater then start date.
  return endTime.isAfter(startTime);
};

const extractReportFilters = (filterValues) => {
  const filters = [];

  if (filterValues?.userData) {
    filters.push({
      column_name: 'user_id',
      value: filterValues.userData.id,
      type: 'exact',
    });
  }
  if (filterValues?.feedback) {
    filters.push({
      column_name: 'reaction',
      value: filterValues.feedback,
      type: 'exact',
    });
  }
  if (filterValues?.startDate && filterValues?.endDate && filterValues?.timeStamp === 'custom') {
    filters.push({
      value: 'custom',
      column_name: 'created_at',
      startDate: filterValues.startDate,
      endDate: filterValues.endDate,
      type: 'exact',
    });
  } else if (filterValues?.timeStamp && filterValues?.timeStamp !== 'custom') {
    filters.push({
      value: filterValues.timeStamp,
      column_name: 'created_at',
      type: 'exact',
    });
  }

  return filters;
};

const settingsEnum = {
  appearance: 'appearance',
  tools: 'tools',
  admin: 'admin',
  profile: 'profile',
};

const languages = [
  { name: 'English', key: 'english' },
  { name: 'Spanish', key: 'spanish' },
  { name: 'French', key: 'french' },
  { name: 'German', key: 'german' },
  { name: 'Chinese Simplified', key: 'chinese_simplified' },
  { name: 'Chinese Traditional', key: 'chinese_traditional' },
  { name: 'Japanese', key: 'japanese' },
  { name: 'Korean', key: 'korean' },
  { name: 'Portuguese Brazilian', key: 'portuguese_brazilian' },
  { name: 'Portuguese European', key: 'portuguese_european' },
  { name: 'Russian', key: 'russian' },
  { name: 'Italian', key: 'italian' },
  { name: 'Dutch', key: 'dutch' },
  { name: 'Arabic', key: 'arabic' },
  { name: 'Turkish', key: 'turkish' },
  { name: 'Hindi', key: 'hindi' },
  { name: 'Bengali', key: 'bengali' },
  { name: 'Punjabi', key: 'punjabi' },
  { name: 'Urdu', key: 'urdu' },
  { name: 'Indonesian', key: 'indonesian' },
  { name: 'Malay', key: 'malay' },
  { name: 'Thai', key: 'thai' },
  { name: 'Vietnamese', key: 'vietnamese' },
  { name: 'Swedish', key: 'swedish' },
  { name: 'Norwegian', key: 'norwegian' },
  { name: 'Danish', key: 'danish' },
  { name: 'Finnish', key: 'finnish' },
  { name: 'Polish', key: 'polish' },
  { name: 'Czech', key: 'czech' },
  { name: 'Slovak', key: 'slovak' },
  { name: 'Hungarian', key: 'hungarian' },
  { name: 'Romanian', key: 'romanian' },
  { name: 'Greek', key: 'greek' },
  { name: 'Hebrew', key: 'hebrew' },
  { name: 'Ukrainian', key: 'ukrainian' },
  { name: 'Serbian', key: 'serbian' },
  { name: 'Croatian', key: 'croatian' },
  { name: 'Bulgarian', key: 'bulgarian' },
  { name: 'Slovenian', key: 'slovenian' },
  { name: 'Lithuanian', key: 'lithuanian' },
  { name: 'Latvian', key: 'latvian' },
  { name: 'Estonian', key: 'estonian' },
];

const LearnsEnum = {
  examples: 'example_prompts',
  builder: 'prompt_builder',
};

const handleCategoryClear = () => async (dispatch) => {
  // clear selected category
  dispatch(setSelectedCategory(null));
  localStorage.removeItem('selectedCategory');
  // clear selected file
  dispatch(setSelectedFile(null));
  localStorage.removeItem('selectedFile');
};

export const defaultSettingsKeys = {
  SOURCE_LIMIT: 'SOURCE_LIMIT',
  SIMILARITY_SCORE: 'SIMILARITY_SCORE',
  PERSONALITY_PROMPT: 'PERSONALITY_PROMPT',
  LOGO: 'LOGO',
  CHAT_LOGO: 'CHAT_LOGO',
  PROMPT_LIBRARY: 'PROMPT_LIBRARY',
  PROMPT_BUILDER: 'PROMPT_BUILDER',
  USER_PROFILE_OPTIONS: 'USER_PROFILE_OPTIONS',
  INVITE_USER: 'INVITE_USER',
  SUGGESTION_PROMPT: 'SUGGESTION_PROMPT',
};
export const themeKeys = {
  dark: 'DARK',
  light: 'LIGHT',
};

export const domainType = [
  { label: 'Exclude Domain', value: 'exclude_domain' },
  { label: 'Include Domain', value: 'include_domain' },
];

export const checkIfGPT4 = (selectedPrompt) =>
  (selectedPrompt?.search_type || '').toLowerCase().includes('gpt4') ||
  (selectedPrompt?.model_name || '').toLowerCase().includes('gpt-4');
export const checkIfCLAUDE3 = (selectedPrompt) => (selectedPrompt?.model_name || '').toLowerCase().includes('claude-3');

export const selectActivePompt = (array) => async (dispatch) => {
  const search = array.find((tool) => !tool.is_disabled);
  if (search) {
    localStorage.setItem('search', JSON.stringify(search));
    dispatch(setSelectedPrompt(search));
  } else {
    localStorage.removeItem('search');
    dispatch(setSelectedPrompt(null));
  }
};

export const addLoadingChat = (chatId) => async (dispatch, getState) => {
  const state = getState().Chat;
  dispatch(setLoadingChats([...state.loadingChats, Number(chatId)]));
};

export const removeLoadingChat = (chatId) => async (dispatch, getState) => {
  const state = getState().Chat;
  dispatch(setLoadingChats(state.loadingChats.filter((id) => id !== Number(chatId))));
};

export const addUnreadChat = (chatId) => async (dispatch, getState) => {
  const state = getState().Chat;
  dispatch(setUnreadChats([...state.unreadChats, Number(chatId)]));
};

export const removeUnreadChat = (chatId) => async (dispatch, getState) => {
  const state = getState().Chat;
  dispatch(setUnreadChats(state.unreadChats.filter((id) => id !== Number(chatId))));
};

export {
  compareStartEndTime,
  debounce,
  errorMessageHandler,
  extractReportFilters,
  formatUserData,
  handleCategoryClear,
  languages,
  LearnsEnum,
  settingsEnum,
};
